/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Courier New', Courier, monospace;
}

body {
    background-color: #1c1c1c;
    color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

/* Main App Container */
App {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Responsive Styling for Main Layout */
@media (max-width: 768px) {
    App {
        font-size: 13px;
    }
}

/* Header Styling */
header {
    text-align: left;
    margin-top: 20px;
    width: 100%;
    max-width: 1000px;
}

header h1 {
    color: #00aaff;
    font-size: 2em;
    margin-top: 50px;
}

header h2 {
    color: #ccc;
    font-size: 1.5em;
}

header hr {
    border: none;
    border-top: 1px solid #f5f5f5;
    margin: 10px 0;
}

header p a {
    color: #00aaff;
    text-decoration: none;
}

header p a:hover {
    text-decoration: underline;
}

/* Navigation Styling */
nav {
    display: flex;
    gap: 10px;
    margin: 20px 0;
}

nav button {
    background-color: #333;
    color: #f5f5f5;
    border: 1px solid #00aaff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

nav button:hover {
    background-color: #00aaff;
}

/* Responsive Styling for Navigation */
@media (max-width: 480px) {
    nav {
        flex-direction: column;
        gap: 5px;
    }

    button {
        width: 100%;
        font-size: 1em;
        padding: 12px;
    }
}

/* Main Section Container */
main {
    width: 100%;
    max-width: 1000px;
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Section Styling */
section {
    display: none;
}

section.active {
    display: block;
}

/* Section Headers */
section h3 {
    color: #00aaff;
    margin-bottom: 15px;
    font-size: 1.2em;
}

section h4 {
    padding-bottom: 10px;
    font-size: 16px;
    color: #f5f5f5;
}

section p {
    line-height: 1.6;
    color: #f5f5f5;
}

section ul {
    line-height: 1.6;
    padding-left: 30px;
    color: #f5f5f5;
}

/* Footer Styling */
footer {
    text-align: right;
    margin-top: 5px;
    width: 100%;
    max-width: 1000px;
    font-size: 0.9em;
}

/* Responsive Styling for Section and Footer */
@media (max-width: 768px) {
    header {
        width: 90%;
        margin-top: 10px;
    }

    header .title {
        font-size: 2em;
        padding-top: 20px;
    }

    header .subtitle {
        font-size: 1.5em;
    }

    main {
        width: 90%;
        padding: 15px;
    }

    footer {
        width: 90%;
        text-align: center;
    }
}
